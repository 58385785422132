import { Injectable } from '@angular/core';
import { StorageService } from './storage-service.service';
import { Order } from './order';
import { OrderItems } from './order-items';

@Injectable({
  providedIn: 'root'
})
export class OrderStorageApiService {
  order:Order
 
  private  destinationKey:string="kassaOrder"
  
  constructor( private storageService:StorageService) {
   
    this.loadOrders();
    this.storageService.changes.subscribe(data=>{
      this.loadOrders()})
  }

  private calculateTotal(){
    let total:number=0;    
    this.order.orderitems.forEach(function(orderitem) {
      total=Number(total)+Number(orderitem.amount)*Number(orderitem.itemprice);
    });
    this.order.total=total;

    let opentopay=this.order.total;
    this.order.paieditems.forEach(function(paieditem) {
      opentopay=Number(opentopay)-Number(paieditem);
    });
    this.order.opentopay=opentopay
  }

 

  public addOrderItem(orderitem:OrderItems){
    console.log("saletius");
    this.order.orderitems.push(orderitem);
    this.calculateTotal();
    this.storageService.store(this.destinationKey,this.order);
    

  }

  public addPaied(value:number){
    console.log("paied");
    this.order.paieditems.push(value);
    this.calculateTotal();
    this.storageService.store(this.destinationKey,this.order);   
  }

  public removePaied(index){
    console.log("paied");
    this.order.paieditems.splice(index, 1);
    this.calculateTotal();
    this.storageService.store(this.destinationKey,this.order);   
  }

  public manipulateAmount(index,manipulation){
    this.order.orderitems[index].amount=(this.order.orderitems[index].amount+manipulation)
    if(this.order.orderitems[index].amount<1){
      this.order.orderitems.splice(index, 1);
    }
    this.calculateTotal();
    this.storageService.store(this.destinationKey,this.order);
  }

  public clearOrder(){
    let orderu= new Order();
    orderu.paymentmethod=-1;
    this.storageService.store(this.destinationKey,orderu);
  }

  private loadOrders(){
    console.log("showitbaby");
    let dataTemp=this.storageService.getStorage().find(x=>x.key===this.destinationKey);

    

    if(!dataTemp){ 
      console.log("no");
      let orderu= new Order();
      orderu.paymentmethod=-1;
      this.storageService.store(this.destinationKey,orderu);
    }else{

      
      let odi:Order=dataTemp.value as Order;
      console.log(odi);
      this.order=odi;
    }
    
    
  }
}
