import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { KassascreenComponent }   from './kassascreen/kassascreen.component';
import { KassaBackendComponent } from './kassa-backend/kassa-backend.component';


const routes: Routes = [
  { path: 'kassascreen', component:KassascreenComponent },
  { path: 'kassabackend', component:KassaBackendComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}

