import { Component, OnInit } from '@angular/core';
import { StorageService } from '../storage-service.service';
import { Order } from '../order';
import { ProductConfStorageApiService } from '../product-conf-storage-api.service';

@Component({
  selector: 'app-kassascreen',
  templateUrl: './kassascreen.component.html',
  styleUrls: ['./kassascreen.component.css']
})
export class KassascreenComponent implements OnInit {
  order:Order
  
  constructor(
    private storageServie: StorageService,
    private productConfStorageApiService:ProductConfStorageApiService
  ) { }

  ngOnInit() {
    
  }

  
}
