import { Component, OnInit } from '@angular/core';
import { StorageService } from '../storage-service.service';
import { Order } from '../order';
import { Observable } from 'rxjs';
import { OrderStorageApiService } from '../order-storage-api.service';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-orderreceipt',
  templateUrl: './orderreceipt.component.html',
  styleUrls: ['./orderreceipt.component.css']
})
export class OrderreceiptComponent implements OnInit {
 // @Output() manipulateOrderItemAmount: EventEmitter<any> = new EventEmitter();

  constructor(
    public orderStorageApiService:OrderStorageApiService
  ) { }

  ngOnInit() {
      console.log(this.orderStorageApiService.order);
  }

  manipulateAmount(index,manipulation){
    this.orderStorageApiService.manipulateAmount(index,manipulation);
  }

  removePaied(index){
    this.orderStorageApiService.removePaied(index);
  }

  

  

}
