import { OrderItems } from "./order-items";

export class Order {
    total:number
    paymentmethod:number
    orderitems: OrderItems[]
    paieditems:number[]
    opentopay:number
    kasse:number

    constructor(){
        this.total=0;
        this.paymentmethod=-1;
        this.orderitems=[]
        this.paieditems=[]
        this.opentopay=0;
        this.kasse;
    }
}
