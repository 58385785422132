import { environment } from '../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Product } from './product'


const API_URL = environment.apiUrl;

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) { }

 /* getAllProducts(): Observable<Product[]> {
    console.log("saleti2");
    var test= this.http
      .get(API_URL + '/api/products')
      .pipe(map(response => {
        const products = response.json();
        return products.map((todo) => new Product());
      }));  
     console.log(test);
     return test; 
  } */

  post(path: string, body, headerType:string, authenticate:boolean): Observable<any> {
    let headers =  this.setHeaders(headerType, authenticate);
    return this.http.post(API_URL + path, body, { headers })
  }

  get(path: string,headerType:string, authenticate:boolean): Observable<any> {
    let headers =  this.setHeaders(headerType, authenticate);
    return this.http.get(API_URL +path, { headers }); 
  }

  put(path: string, body, headerType:string, authenticate:boolean): Observable<any> {
    let headers =  this.setHeaders(headerType, authenticate);
    return this.http.put(API_URL + path, body, { headers })
  }

  delete(path: string, body, headerType:string, authenticate:boolean): Observable<any> {
    let headers =  this.setHeaders(headerType, authenticate);
    return this.http.delete(API_URL + path, { headers })
  }

  setHeaders(headerType, authenticate) {
    let headersConf = {};   
    //ContentType
    if(headerType === 'json') {
      headersConf["Content-Type"] = "application/json";
    } else if(headerType === 'json-x') {
      headersConf["Content-Type"] = "application/json";
    } else if(headerType === 'form') {
      headersConf["Content-Type"] = "application/x-www-form-urlencoded";
    } else if(headerType === 'multipart') {
      headersConf["Content-Type"] = "multipart/form-data";
    }
    //Authorization
    if(authenticate) {
      /*if(localStorage.getItem("currentUser")) {
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
        let bearer = 'bearer '+this.currentUser.access_token;
        headersConf["authorization"] = bearer;
      }*/
    }
    return new HttpHeaders(headersConf);
  }
/*
  post(path: string, body, headerType:string, authenticate:boolean): Observable<any> {
    let headers =  this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { headers })
  }*/
}
