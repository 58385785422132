import { Product } from "./product";
import { Productconfgroup } from "./productconfgroup";

export class OrderItems {
    amount:number
    productid:number
    productname:string
    productconfiguration:number[]
    productconfigurationtxt:string;
    openconfgroups:number[]
    itemprice:number
    totalprice:number
    product:Product

    constructor(product:Product){
        this.amount=1;
        this.productid=product.productid;
        this.productname=product.productname;
        this.productconfiguration=[];
        this.productconfigurationtxt="";
        this.openconfgroups=product.configgroups;
        this.itemprice=product.price;
        this.totalprice=product.price;
        this.product=product;
    }

    public getNextOpenConf():Productconfgroup{
        if(this.openconfgroups.length>0) {
            return new Productconfgroup(this.productid,this.openconfgroups[0]) 
        }else{
            return new Productconfgroup(-1,-1);
        }
    }

    public removeFirstOpenConf(){
        if(this.openconfgroups.length>0) {
            this.openconfgroups.shift();
        }        
    }
}
