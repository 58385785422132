import { environment } from '../environments/environment'
import { Injectable } from '@angular/core'
import { Http, Response, Headers } from '@angular/http'
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Product } from './product'


const API_URL = environment.apiUrl;

@Injectable()
export class ProductApiService {
  constructor(private http : Http) { }

 /* getAllProducts(): Observable<Product[]> {
    console.log("saleti2");
    var test= this.http
      .get(API_URL + '/api/products')
      .pipe(map(response => {
        const products = response.json();
        return products.map((todo) => new Product());
      }));
     console.log(test);
     return test; 
  } */

  getAllProducts(): Observable<any> {
    return this.http.get(API_URL + '/api/products');
  }
/*
  post(path: string, body, headerType:string, authenticate:boolean): Observable<any> {
    let headers =  this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { headers })
  }*/
}
