import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../environments/environment'
import { ProductApiService } from '../product-api.service';
import { ApiService } from '../api-service.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { Product } from '../product';
import { OrderStorageApiService } from '../order-storage-api.service';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Productconfgroup } from '../productconfgroup';

@Component({
  selector: 'app-kassaproduct-conf',
  templateUrl: './kassaproduct-conf.component.html',
  styleUrls: ['./kassaproduct-conf.component.css']
})
export class KassaproductConfComponent implements OnInit {
  @Output() addProductConf: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set productconfgroup(productconfgroup: Productconfgroup) {
    if(productconfgroup.productid!=-1 && productconfgroup.productid!=undefined){
      this.productconfs$= this.apiService.get('/api/products/configs/'+productconfgroup.productid+"/"+
      productconfgroup.confgroupid, 'json', true)
    .pipe(map(
      data => {
        console.log(data);
        return data;
      } 
    )); 
    }
   
  }

  productconfs$: Observable<[Product]>;
  apiUrl:string;

  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
    this.apiUrl=environment.apiUrl
  }

  clickProduct(product){
    //console.log(this.products$.find(x=>x.productid===productid));
    
    this.addProductConf.emit(product);
  }

}
