import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api-service.service';
import { OrderStorageApiService } from '../order-storage-api.service';
import { OrderItems } from '../order-items';
import { ProductConfStorageApiService } from '../product-conf-storage-api.service';
import { Product } from '../product';
import { Productconfgroup } from '../productconfgroup';


@Component({
  selector: 'app-kassa-backend',
  templateUrl: './kassa-backend.component.html',
  styleUrls: ['./kassa-backend.component.css']
})
export class KassaBackendComponent implements OnInit {
  private tempOrderItem:OrderItems;
  public kasse:number;
  constructor(
    private apiService:ApiService,
    private orderStorageApiService: OrderStorageApiService,
    private productConfStorageApiService: ProductConfStorageApiService
  ) { }

  ngOnInit() {
    this.kasse=0;
  }

  onAddProduct(product){
    let tempProduct:Product=this.deepCopy(product);
    this.tempOrderItem=new OrderItems(tempProduct); 
    this.confProduct();
  }

  addPaied(value:number){
    this.orderStorageApiService.addPaied(value);
  }

  /**
 * Returns a deep copy of the object
 */
public deepCopy(oldObj: any) {
  var newObj = oldObj;
  if (oldObj && typeof oldObj === "object") {
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
          newObj[i] = this.deepCopy(oldObj[i]);
      }
  }
  return newObj;
}

  onAddConfProduct(product){
    console.log(product);
    this.tempOrderItem.productconfiguration.push(product.productid)
    this.tempOrderItem.productconfigurationtxt+=product.productname+" ";
    this.tempOrderItem.itemprice=Number(this.tempOrderItem.itemprice)+Number(product.priceplus);
    console.log(this.tempOrderItem.itemprice);
    this.tempOrderItem.totalprice=Number(this.tempOrderItem.itemprice)*Number(this.tempOrderItem.amount);
    this.tempOrderItem.removeFirstOpenConf();
    this.confProduct();
  }

  confProduct(){
    if(this.tempOrderItem){
      let nextGroup:Productconfgroup=this.tempOrderItem.getNextOpenConf();
      if(nextGroup.productid!=-1){
          this.productConfStorageApiService.openconfigproductgroupid(nextGroup);
          console.log("sandra isch leider au da"+nextGroup);
      }else{

        this.productConfStorageApiService.openconfigproductgroupid(new Productconfgroup(-1,-1));
        this.orderStorageApiService.addOrderItem(this.tempOrderItem);
        this.tempOrderItem=undefined;
      }
    }
  }

  checkout(paymentmethod){
    let order=this.orderStorageApiService.order;
    order.paymentmethod=paymentmethod;
    order.kasse=this.kasse;
    this.apiService.post("/api/orders",order,'json', true).toPromise().then(x => console.log("x is undefined!!! " + x));
    this.orderStorageApiService.clearOrder();  
  }

  reset(){
    localStorage.clear();
    location.reload();
  }

}
