import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'
import { ProductApiService } from '../product-api.service';
import { ApiService } from '../api-service.service';
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { Product } from '../product';
import { OrderStorageApiService } from '../order-storage-api.service';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-kassaproduct',
  templateUrl: './kassaproduct.component.html',
  styleUrls: ['./kassaproduct.component.css'],
  providers: [ProductApiService]
})
export class KassaproductComponent implements OnInit {
  @Output() addProduct: EventEmitter<any> = new EventEmitter<any>();
  products$: Observable<[Product]>;
  apiUrl:string;



  constructor(
    private apiService: ApiService
  ) { }

  ngOnInit() {
      this.apiUrl=environment.apiUrl
      this.products$= this.apiService.get('/api/products', 'json', true)
      .pipe(map(
        data => {
          console.log(data);
          return data;
        } 
      ));    
    
  }

  clickProduct(product){
    this.addProduct.emit(product);
  }

}
