import { HttpModule } from '@angular/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { KassaproductComponent } from './kassaproduct/kassaproduct.component';
import { KassascreenComponent } from './kassascreen/kassascreen.component';
import { AppRoutingModule } from './/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { ApiService } from './api-service.service'; 
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { StorageService } from './storage-service.service';
import { KassaBackendComponent } from './kassa-backend/kassa-backend.component';
import { OrderreceiptComponent } from './orderreceipt/orderreceipt.component';
import { KassaproductConfComponent } from './kassaproduct-conf/kassaproduct-conf.component';
import { LayoutModule } from '@angular/cdk/layout';

@NgModule({
  declarations: [
    AppComponent,
    KassaproductComponent,
    KassascreenComponent,
    KassaBackendComponent,
    OrderreceiptComponent,
    KassaproductConfComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MaterialModule,
    BrowserAnimationsModule,
    MaterialModule,
    LayoutModule
  ],
  providers: [ApiService,StorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
