import { Injectable } from '@angular/core';
import { StorageService } from './storage-service.service';
import { Productconfgroup } from './productconfgroup';

@Injectable({
  providedIn: 'root'
})
export class ProductConfStorageApiService {

  productconfgroup:Productconfgroup
 
  private  destinationKey:string="configproductgroupid"
  
  constructor( private storageService:StorageService) {
   
    this.checkConfigproductgroupid();
    this.storageService.changes.subscribe(data=>{
      this.checkConfigproductgroupid()})
  }

  public openconfigproductgroupid( productconfgroup:Productconfgroup){
    this.productconfgroup= productconfgroup;
    this.storageService.store(this.destinationKey,this.productconfgroup);
  }

  private checkConfigproductgroupid(){
    console.log("showitbabyConfig");
    let dataTemp=this.storageService.getStorage().find(x=>x.key===this.destinationKey);    

    if(!dataTemp){ 
      let productconfgroup = new Productconfgroup(-1,-1);
      this.storageService.store(this.destinationKey,productconfgroup);
    }else{      
      this.productconfgroup=dataTemp.value as Productconfgroup;      
    } 
    
    
  }
}
